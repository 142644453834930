export const mobileBreakpointSm = 321;
export const mobileBreakpoint = 481;
export const tabletBreakpoint = 769;
export const landscapeTabletBreakpoint = 1025;
export const mobileBreakpointSmPx = `${mobileBreakpointSm}px`;
export const mobileBreakpointPx = `${mobileBreakpoint}px`;
export const tabletBreakpointPx = `${tabletBreakpoint}px`;
export const landscapeTabletBreakpointPx = `${landscapeTabletBreakpoint}px`;

export const reducedSizeBreakpoint = tabletBreakpoint - 1;
export const reducedSizeBreakpointPx = `${reducedSizeBreakpoint}px`;

export const reducedSizeLandscapeBreakpoint = landscapeTabletBreakpoint - 1;
export const reducedSizeLandscapeBreakpointPx = `${reducedSizeLandscapeBreakpoint}px`;
