import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useOktaAuth } from '@okta/okta-react';
import { Button, Snackbar } from '@mui/material';
import { Delete, Add, Save, FileCopy, Refresh, NotificationsActive, NotificationsOff } from '@mui/icons-material';
import Label from './Label';
import Input from './Input';
import useInput from './UseInputHook';
import { USE_DEVELOPMENT, SERVER_ENDPOINT } from '../../environment';

const Form = styled.form`
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
`;

const AdminListItem = styled.li`
    outline: none;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    align-items: center;
    height: 40px;
    margin-bottom: 5px;
    background: white;
    color: black;
`;

const mapObjectKeysToOptions = optionsEnum =>
    Object.keys(optionsEnum).map(optionKey => {
        const optionName = optionsEnum[optionKey];
        const optionNameCapitalized = optionName.charAt(0).toUpperCase() + optionName.slice(1);
        return (
            <option key={optionKey} value={optionName}>
                {optionNameCapitalized}
            </option>
        );
    });
const vimeoLiveStreamStateOptionsList = mapObjectKeysToOptions({
    NOT_STARTED: 'not started',
    PLAYING: 'playing',
    PAUSED: 'paused',
    ENDED: 'ended'
});
const playlistTypeOptionsList = mapObjectKeysToOptions({
    VIMEO: 'vimeo',
    YOUTUBE: 'youtube'
});

const vimeoTokenSourceOptionsList = mapObjectKeysToOptions({
    UNITY: 'unity',
    NYSE: 'nyse'
});

const webGLEncodingList = mapObjectKeysToOptions({
    BROTLI: 'Brotli',
    GZIP: 'Gzip',
    NONE: 'None'
});

const getNYTimezoneString = dateStr =>
    new Date(dateStr).toLocaleString('en-US', { timeZone: 'america/new_york', timeZoneName: 'short' });

const AdminForm = ({ userInfo, settings, initialDebugSettings }) => {
    useEffect(() => {
        document.title = 'Admin Dashboard';
    }, []);

    // State
    const { value: liveStreamStartTime, onChange: onChangeLiveStreamStartTime } = useInput(
        getNYTimezoneString(settings.liveStreamStartTime)
    );
    const { value: bellStartTime, onChange: onChangeBellStartTime } = useInput(
        getNYTimezoneString(settings.bellStartTime)
    );
    const { value: youTubePlaylistId, onChange: onChangeYouTubePlaylistId } = useInput(settings.youTubePlaylistId);
    const { value: vimeoLiveStreamId, onChange: onChangeVimeoLiveStreamId } = useInput(settings.vimeoLiveStream.id);
    const { value: vimeoLiveStreamHash, onChange: onChangeVimeoLiveStreamHash } = useInput(
        settings.vimeoLiveStream.hash
    );
    const { value: vimeoLiveStreamState, onChange: onChangeVimeoLiveStreamState } = useInput(
        settings.vimeoLiveStream.state
    );
    const { value: vimeoTokenSource, onChange: onChangeVimeoTokenSource } = useInput(settings.vimeoTokenSource);

    const { value: playlistType, onChange: onChangePlaylistType } = useInput(settings.playlistType);
    const { value: vimeoReplayUrl, onChange: onChangeVimeoReplayUrl } = useInput(settings.vimeoReplayUrl);
    const { value: vimeoReplayThumbnailUrl, onChange: onChangeVimeoReplayThumbnailUrl } = useInput(
        settings.vimeoReplayThumbnailUrl
    );
    const { value: isTrading, onChange: onChangeIsTrading } = useInput(settings.isTrading);
    const { value: isReactionEnabled, onChange: onChangeReactionEnabled } = useInput(settings.reactionEnabled);
    const { value: reactionThresholdValue, onChange: onChangeReactionThreshold } = useInput(settings.reactionThreshold);
    const { value: isPostStreamSignatureEnabled, onChange: onChangeIsPostStreamSignatureEnabled } = useInput(
        settings.postStreamSignatureEnabled
    );
    const { value: isSocialMediaAssetsEnabled, onChange: onChangeIsSocialMediaAssetsEnabled } = useInput(
        settings.socialMediaAssetsEnabled
    );
    const { value: isChatEnabled, onChange: onChangeIsChatEnabled } = useInput(settings.chatEnabled);
    const { value: isPhotoboothEnabled, onChange: onChangePhotoboothEnabled } = useInput(settings.photoboothEnabled);
    const { value: isVideoCallBackgroundEnabled, onChange: onChangeVideoCallBackgroundEnabled } = useInput(
        settings.videoCallBackgroundEnabled
    );

    const { value: chatIdValue, onChange: onChangeChatId } = useInput(settings.chatId);
    const { value: chatHashValue, onChange: onChangeChatHash } = useInput(settings.chatHash);

    const { value: isAnalyticsEnabled, onChange: onChangeAnalyticsEnabled } = useInput(settings.analyticsEnabled);

    const { value: closedCaptionEventNameValue, onChange: onClosedCaptionEventName } = useInput(
        settings.closedCaptionEventName
    );
    const { value: closedCaptionLanguagesValue, onChange: onClosedCaptionLanguages } = useInput(
        settings.closedCaptionLanguages
    );

    /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Debug Settings Only available when USE_DEVELOPMENT is set.

    /*
        devDebugSettings: {
            buildType: 'cloud' | 'other'
            buildNumber: <integer> // number of the build for dev/prod builds
            otherBuildPath: <string> // path in the bucket for the 'other' build
            includeWebglSymbols: <bool> // applicable only for 'other' builds 
            webglBuildCompression: 'Brotli' | 'Gzip' | 'None' // see webGLEncodingList for options. Applicable only for 'other' builds
        }
    */

    const [devDebugSettings, setDevDebugSettings] = useState(initialDebugSettings);

    const isDevCloudBuild = sett => sett.buildType !== undefined && sett.buildType !== 'other';

    /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const { value: adminsList, setValue: setAdminsList } = useInput(settings.admins);
    const { authState } = useOktaAuth();

    const [forceBellLive, setNewForceBellLiveValue] = useState(settings.forceBellLive);
    const [newAdminValue, setNewAdminValue] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const currentAdmins = adminsList || [];

    const handleSnackbarClose = () => {
        setSnackbarMessage('');
    };

    const handleNewAdminChange = e => {
        setNewAdminValue(e.target.value);
    };

    const handleForceBellLive = ({ forceBellLiveValue, bellStartTimeValue }) => async event => {
        event.stopPropagation();
        event.preventDefault();
        // LP-4494 Forcebell ringing - Notification bar missing for "ring the bell" information
        // information modal will not show if bell start time is set to the past
        if (forceBellLiveValue && new Date(bellStartTimeValue) < new Date()) {
            setSnackbarMessage('Please ensure bell start time is set to the future compared to current time');
            return;
        }

        setNewForceBellLiveValue(forceBellLiveValue);
        try {
            const newSettings = {
                forceBellLive: forceBellLiveValue
            };

            const response = await fetch(`${SERVER_ENDPOINT}/settings`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${authState.accessToken.accessToken}`
                },
                body: JSON.stringify(newSettings)
            });

            const parsedResponse = await response.json();
            if (parsedResponse.error) {
                throw parsedResponse.error;
            }
            console.info(parsedResponse);
            // LP-4493 Force ringing - messages are wrong (reversed) for "force bell ringing" and "reset bell ringing" button
            setSnackbarMessage(
                forceBellLiveValue === true ? 'Sucessfully forced bell to go live' : 'Successfully reset the bell'
            );
        } catch (error) {
            setSnackbarMessage(
                forceBellLiveValue === true ? 'Failed to force the bell to go live' : 'Failed to reset the bell'
            );
        }
    };

    const validateDebugSettings = newSettings => {
        if (
            (newSettings.buildType === 'dev' || newSettings.buildType === 'prod') &&
            Number.isNaN(parseInt(newSettings.buildNumber, 10))
        ) {
            throw Error(
                `DebugSettings are not valid: build type '${newSettings.buildType}' and build number is not a valid number.`
            );
        }
    };

    const submitDebugSettings = async () => {
        if (USE_DEVELOPMENT) {
            const newSettings = { ...devDebugSettings };

            validateDebugSettings(newSettings);

            const response = await fetch(`${SERVER_ENDPOINT}/debugSettings`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${authState.accessToken.accessToken}`
                },
                body: JSON.stringify(newSettings)
            });

            const parsedResponse = await response.json();
            if (parsedResponse.error) {
                throw parsedResponse.error;
            }
        }
    };

    const getErrorAsDisplayableString = error => {
        if (!error) {
            return '';
        }

        if (error instanceof Error) {
            return error.message;
        }

        if (Array.isArray(error)) {
            let result = '';
            for (let i = 0; i < error.length; i++) {
                result += `\n${error[i]}.`;
            }
            return `"${result.substring(1)}"`;
        }

        // works with regular string and as a fallback for other non-array types.
        return JSON.stringify(error);
    };

    const handleSubmit = async event => {
        event.stopPropagation();
        event.preventDefault();

        try {
            const newSettings = {
                liveStreamStartTime,
                bellStartTime,
                youTubePlaylistId,
                playlistType,
                vimeoLiveStream: {
                    id: vimeoLiveStreamId,
                    state: vimeoLiveStreamState,
                    hash: vimeoLiveStreamHash
                },
                vimeoReplayUrl,
                vimeoReplayThumbnailUrl,
                vimeoTokenSource,
                admins: adminsList,
                isTrading,
                postStreamSignatureEnabled: isPostStreamSignatureEnabled,
                reactionEnabled: isReactionEnabled,
                reactionThreshold: reactionThresholdValue,
                socialMediaAssetsEnabled: isSocialMediaAssetsEnabled,
                videoCallBackgroundEnabled: isVideoCallBackgroundEnabled,
                analyticsEnabled: isAnalyticsEnabled,
                chatEnabled: isChatEnabled,
                photoboothEnabled: isPhotoboothEnabled,
                chatId: chatIdValue,
                chatHash: chatHashValue,
                closedCaptionEventName: closedCaptionEventNameValue,
                closedCaptionLanguages: closedCaptionLanguagesValue
            };

            const response = await fetch(`${SERVER_ENDPOINT}/settings`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${authState.accessToken.accessToken}`
                },
                body: JSON.stringify(newSettings)
            });

            const parsedResponse = await response.json();
            if (parsedResponse.error) {
                throw parsedResponse.error;
            }
            console.info(parsedResponse);

            await submitDebugSettings();

            setSnackbarMessage('Successfully saved new settings');
        } catch (error) {
            setSnackbarMessage(`Error while saving settings.\n${getErrorAsDisplayableString(error)}`);
        }
    };

    const handleRefreshPlaylist = async () => {
        // if playlistId is blank, don't even bother
        if (youTubePlaylistId.length === 0) {
            setSnackbarMessage(`playlist ID is empty, please input a valid playlist ID`);
            return;
        }

        try {
            const body = {
                youTubePlaylistId,
                playlistType
            };
            const response = await fetch(`${SERVER_ENDPOINT}/refresh-videos`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${authState.accessToken.accessToken}`
                },
                body: JSON.stringify(body)
            });

            const parsedResponse = await response.json();
            if (parsedResponse.error) {
                throw parsedResponse.error;
            }
            console.info(parsedResponse);
            setSnackbarMessage(
                'Successfully refreshed youtube videos ids on server\nREMEBER TO SAVE IF YOU CHANGED PLAYLIST TYPE'
            );
        } catch (error) {
            setSnackbarMessage(`Error while refreshing youtube videos ids ${error}`);
        }
    };

    const handleDeletePlaylist = async () => {
        try {
            const response = await fetch(`${SERVER_ENDPOINT}/videos`, {
                method: 'DELETE',
                headers: {
                    authorization: `Bearer ${authState.accessToken.accessToken}`
                }
            });

            if (response.status !== 204) {
                const parsedResponse = await response.json();
                if (parsedResponse.error) {
                    throw parsedResponse.error;
                }
            }
            setSnackbarMessage('Successfully cleared youtube videos ids on server');
        } catch (error) {
            setSnackbarMessage(`Error while clearing youtube videos ids ${error}`);
        }
    };

    const handleRefreshVimeoLiveURL = async () => {
        try {
            const response = await fetch(`${SERVER_ENDPOINT}/refresh-vimeo`, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${authState.accessToken.accessToken}`
                }
            });

            if (response.status !== 204) {
                const parsedResponse = await response.json();
                if (parsedResponse.error) {
                    throw parsedResponse.error;
                }
            }
            setSnackbarMessage('Successfully refreshed vimeo live url on server');
        } catch (error) {
            setSnackbarMessage(`Error while refreshing vimeo live url ${error}`);
        }
    };

    const removeAdmin = adminToRemove => () => {
        if (adminsList.length === 1) {
            setSnackbarMessage('Error: Must keep at least one Admin');
            return;
        }

        setAdminsList(adminList => adminList.filter(currentAdmin => currentAdmin !== adminToRemove));
    };

    const addAdmin = event => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        setAdminsList([...adminsList, newAdminValue]);
        setNewAdminValue('');
    };

    const handleKeyPress = event => {
        // catch Enter
        if (event.key === 'Enter') {
            addAdmin();
            event.preventDefault();
            event.stopPropagation();
        }
    };

    return (
        <>
            <h1>Admin Dashboard</h1>
            <p>Welcome back, {userInfo.name}! - Please be careful.</p>
            <Button
                variant='contained'
                size='small'
                startIcon={<FileCopy />}
                onClick={() => {
                    navigator.clipboard.writeText(authState.accessToken.accessToken);
                    setSnackbarMessage('Copied access token to clipboard');
                }}>
                Copy Access Token to Clipboard
            </Button>
            <Form action='#' onSubmit={handleSubmit}>
                <fieldset>
                    <h3>
                        Time&nbsp;<span style={{ fontSize: '14px', fontWeight: 'normal' }}>(in NYC time zone)</span>
                    </h3>
                    <Label title='Example:'>
                        <i>2022-02-14, 4:44:20 PM EST</i>
                    </Label>
                    <p />
                    <Input
                        label='Event / Live Stream Start Time'
                        type='text' // want datetime-local
                        name='liveStreamStartTime'
                        value={liveStreamStartTime}
                        onChange={onChangeLiveStreamStartTime}
                    />
                    <Input
                        label='Bell Ringing Start Time'
                        type='text' // want datetime-local
                        name='bellStartTime'
                        value={bellStartTime}
                        onChange={onChangeBellStartTime}
                    />
                </fieldset>
                <fieldset>
                    <h3>Vimeo Live Stream </h3>
                    <Input
                        label='Id - used for generating HLS link'
                        type='text'
                        name='vimeoLiveStream[id]'
                        placeholder='123456789'
                        value={vimeoLiveStreamId}
                        onChange={onChangeVimeoLiveStreamId}
                    />
                    <Input
                        label='Hash - used for private videos'
                        type='text'
                        name='vimeoLiveStream[hash]'
                        placeholder='123a456b78'
                        value={vimeoLiveStreamHash}
                        onChange={onChangeVimeoLiveStreamHash}
                    />
                    <Input
                        label='Replay Vimeo Video ID - used after event is over'
                        type='text'
                        name='vimeoReplayUrl'
                        placeholder='123456789'
                        value={vimeoReplayUrl}
                        onChange={onChangeVimeoReplayUrl}
                    />
                    <Input
                        label='Thumbnail for the Replay URL - generates from replay vimeo video ID if no value is given'
                        type='url'
                        name='vimeoReplayThumbnailUrl'
                        placeholder='https://vimeo.com/123456789'
                        value={vimeoReplayThumbnailUrl}
                        onChange={onChangeVimeoReplayThumbnailUrl}
                    />
                    <Label title='State'>
                        <select
                            name='vimeoLiveStream[state]'
                            value={vimeoLiveStreamState}
                            onChange={onChangeVimeoLiveStreamState}>
                            {vimeoLiveStreamStateOptionsList}
                        </select>
                    </Label>
                    <Label title='Vimeo Token Source'>
                        <select name='vimeoTokenSource' value={vimeoTokenSource} onChange={onChangeVimeoTokenSource}>
                            {vimeoTokenSourceOptionsList}
                        </select>
                    </Label>
                    <p style={{ marginTop: '-10px', fontSize: '12px' }}>
                        Note: Do not change the vimeo token source while the live stream is running.
                    </p>
                    <Input
                        label='Vimeo Chat Id'
                        type='text'
                        name='chatIdValue'
                        placeholder='123456789'
                        value={chatIdValue}
                        onChange={onChangeChatId}
                    />
                    <Input
                        label='Vimeo Chat Hash'
                        type='text'
                        name='chatHashValue'
                        placeholder='123a456b78'
                        value={chatHashValue}
                        onChange={onChangeChatHash}
                    />
                </fieldset>

                <fieldset>
                    <h3>Other</h3>

                    <Label title='Playlist Type'>
                        <select name='playlist[type]' value={playlistType} onChange={onChangePlaylistType}>
                            {playlistTypeOptionsList}
                        </select>
                    </Label>
                    <Input
                        label='Playlist Id'
                        type='text'
                        name='PlaylistId'
                        value={youTubePlaylistId}
                        onChange={onChangeYouTubePlaylistId}
                    />
                    <Label>
                        Is trading
                        <span>
                            <input type='checkbox' name='isTrading' checked={isTrading} onChange={onChangeIsTrading} />
                            Is Trading?
                        </span>
                    </Label>
                    <Label>
                        Enable Analytics
                        <span>
                            <input
                                type='checkbox'
                                name='analyticsEnabled'
                                checked={isAnalyticsEnabled}
                                onChange={onChangeAnalyticsEnabled}
                            />
                            Enable Analytics?
                        </span>
                    </Label>
                    <Label>
                        Allow signing after stream
                        <span>
                            <input
                                type='checkbox'
                                name='isPostStreamSignatureEnabled'
                                checked={isPostStreamSignatureEnabled}
                                onChange={onChangeIsPostStreamSignatureEnabled}
                            />
                            Allow signing after stream?
                        </span>
                    </Label>
                    <Label>
                        Allow social media download
                        <span>
                            <input
                                type='checkbox'
                                name='isSocialMediaAssetsEnabled'
                                checked={isSocialMediaAssetsEnabled}
                                onChange={onChangeIsSocialMediaAssetsEnabled}
                            />
                            Allow social media download modal and after stream option?
                        </span>
                    </Label>
                    <Label>
                        Enable Chat
                        <span>
                            <input
                                type='checkbox'
                                name='isChatEnabled'
                                checked={isChatEnabled}
                                onChange={onChangeIsChatEnabled}
                            />
                            Enable the ability to chat?
                        </span>
                    </Label>
                    <Label>
                        Enable Photobooth
                        <span>
                            <input
                                type='checkbox'
                                name='isPhotoboothEnabled'
                                checked={isPhotoboothEnabled}
                                onChange={onChangePhotoboothEnabled}
                            />
                            Enable the ability to use the photobooth?
                        </span>
                    </Label>
                    <Label>
                        Allow video call background download
                        <span>
                            <input
                                type='checkbox'
                                name='isVideoCallBackgroundEnabled'
                                checked={isVideoCallBackgroundEnabled}
                                onChange={onChangeVideoCallBackgroundEnabled}
                            />
                            Allow video call background download before stream?
                        </span>
                    </Label>
                    <Label>
                        Enable/Disable Reaction
                        <span>
                            <input
                                type='checkbox'
                                name='reactionEnabled'
                                checked={isReactionEnabled}
                                onChange={onChangeReactionEnabled}
                            />
                            Allow reactions in client?
                        </span>
                    </Label>
                    <Label>
                        Reaction Achievement Threshold
                        <span>
                            <input
                                type='number'
                                name='reactionThreshold'
                                value={reactionThresholdValue}
                                onChange={onChangeReactionThreshold}
                            />
                        </span>
                    </Label>
                    <p style={{ marginTop: '-10px', fontSize: '12px' }}>
                        Note: Roughly equal to the number of participants.
                    </p>
                    <Label>
                        Closed Caption Event
                        <span>
                            <input
                                type='text'
                                name='closedCaptionEvent'
                                value={closedCaptionEventNameValue}
                                onChange={onClosedCaptionEventName}
                            />
                        </span>
                    </Label>
                    <Label>
                        Closed Caption Languages
                        <span>
                            <input
                                type='text'
                                name='closedCaptionLanguages'
                                value={closedCaptionLanguagesValue}
                                onChange={onClosedCaptionLanguages}
                            />
                        </span>
                    </Label>
                    <p style={{ marginTop: '-10px', fontSize: '12px' }}>
                        Note: Language Ids should be separated by semi-colon, e.g. &#39;en;pt;es&#39;
                    </p>
                </fieldset>
                <fieldset>
                    <h3>Admins</h3>
                    <p>Add or remove admins. Changes are not sent to the server until saving</p>
                    {currentAdmins ? (
                        <>
                            {currentAdmins.map(
                                admin => admin && <input type='hidden' name='admins' value={admin} key={admin} />
                            )}
                            <ul style={{ paddingLeft: '0' }}>
                                {currentAdmins.map(admin => (
                                    <AdminListItem key={`${admin}-li`} style={{ marginBottom: '10px' }}>
                                        {admin}
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            size='small'
                                            startIcon={<Delete />}
                                            onClick={removeAdmin(admin)}>
                                            Remove
                                        </Button>
                                    </AdminListItem>
                                ))}
                            </ul>
                        </>
                    ) : null}
                    <div>
                        <Input
                            label='Add new admin'
                            type='text'
                            name='new-admin'
                            value={newAdminValue}
                            onChange={handleNewAdminChange}
                            onKeyPress={handleKeyPress}
                        />

                        <Button
                            variant='contained'
                            size='small'
                            startIcon={<Add />}
                            disabled={!newAdminValue}
                            onClick={addAdmin}>
                            Add
                        </Button>
                    </div>
                </fieldset>
                <fieldset style={{ backgroundColor: '#b00' }}>
                    <h3>Caution: </h3>
                    <p>Action listed here are client facing and takes effect immediately, use wisely</p>
                    <p>
                        <Button
                            variant='contained'
                            size='small'
                            startIcon={<NotificationsActive />}
                            disabled={forceBellLive}
                            onClick={handleForceBellLive({
                                forceBellLiveValue: true,
                                bellStartTimeValue: bellStartTime
                            })}>
                            Force Bell Ringing
                        </Button>
                    </p>
                    <p>
                        <Button
                            variant='contained'
                            size='small'
                            startIcon={<NotificationsOff />}
                            disabled={!forceBellLive}
                            onClick={handleForceBellLive({
                                forceBellLiveValue: false,
                                bellStartTimeValue: bellStartTime
                            })}>
                            Reset Bell Ringing
                        </Button>
                    </p>

                    <Button variant='contained' size='small' startIcon={<Refresh />} onClick={handleRefreshPlaylist}>
                        Refresh Playlist
                    </Button>
                    <Button variant='contained' size='small' startIcon={<Delete />} onClick={handleDeletePlaylist}>
                        Clear Playlist
                    </Button>
                    <p>
                        <Button
                            variant='contained'
                            size='small'
                            startIcon={<Refresh />}
                            onClick={handleRefreshVimeoLiveURL}>
                            Refresh Vimeo Live URL
                        </Button>
                    </p>
                </fieldset>
                {USE_DEVELOPMENT && (
                    <fieldset style={{ backgroundColor: '#aa0' }}>
                        <h3>Development: </h3>
                        <p>
                            Options listed here are used for debugging. It should be only available in development
                            environments.
                        </p>
                        <BuildTypeOptions buildInfo={devDebugSettings} setBuildInfo={setDevDebugSettings} />
                        {isDevCloudBuild(devDebugSettings) && (
                            <CloudBuildOptions buildInfo={devDebugSettings} setBuildInfo={setDevDebugSettings} />
                        )}
                        {!isDevCloudBuild(devDebugSettings) && (
                            <OtherBuildOptions buildInfo={devDebugSettings} setBuildInfo={setDevDebugSettings} />
                        )}
                    </fieldset>
                )}
                <p style={{ width: '100%' }}>
                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        startIcon={<Save />}
                        onClick={handleSubmit}>
                        Save
                    </Button>
                </p>
            </Form>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={!!snackbarMessage}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </>
    );
};

const CloudBuildOptions = ({ buildInfo, setBuildInfo }) => {
    const buildType = buildInfo.buildType || 'other';
    const prodDevBuildNumber = buildInfo.buildNumber;

    const updateBuildNumber = evt => {
        const newValue = evt.target.value;
        setBuildInfo(info => ({ ...info, buildNumber: newValue }));
    };
    return (
        <p style={{ marginLeft: '20px' }}>
            Build #:
            <span>
                <input
                    style={{ marginLeft: '10px' }}
                    type='text'
                    name='buildNumber'
                    value={prodDevBuildNumber}
                    disabled={buildType === 'other'}
                    alt='The number of the production or development build'
                    onChange={updateBuildNumber}
                />
            </span>
        </p>
    );
};

const BuildTypeOptions = ({ buildInfo, setBuildInfo }) => {
    const otherBuildType = 'other';
    const buildType = buildInfo.buildType !== undefined ? buildInfo.buildType : otherBuildType;

    const setCloudBuild = () => {
        setBuildInfo(info => ({ ...info, buildType: 'cloud' }));
    };

    const setOtherBuild = () => {
        setBuildInfo(info => ({ ...info, buildType: otherBuildType }));
    };

    return (
        <>
            <p>Select Build Type:</p>
            <span>
                <input
                    type='radio'
                    name='isCloudBuild'
                    checked={buildType !== otherBuildType}
                    onChange={setCloudBuild}
                />
                Cloud
            </span>
            <span style={{ marginLeft: '20px' }}>
                <input
                    type='radio'
                    name='isCustomBuild'
                    checked={buildType === otherBuildType}
                    onChange={setOtherBuild}
                />
                Other
            </span>
        </>
    );
};

const WebGLSettingsForm = ({ includeSymbols, compressionType, onIncludeSymbolsChange, onCompressionChange }) => (
    <>
        <Label>
            <span>
                <input
                    type='checkbox'
                    name='devIncludeWebGLSymbols'
                    checked={includeSymbols}
                    onChange={onIncludeSymbolsChange}
                />
                Include symbols when instantiating WebGL
            </span>
        </Label>
        <Label title='WebGL compression'>
            <select name='devWebGLCompression' value={compressionType} onChange={onCompressionChange}>
                {webGLEncodingList}
            </select>
        </Label>
    </>
);

const OtherBuildOptions = ({ buildInfo, setBuildInfo }) => {
    const buildPath = buildInfo.otherBuildPath;
    const buildIncludeSymbols = buildInfo.includeWebglSymbols !== undefined ? buildInfo.includeWebglSymbols : true;
    const buildCompression = buildInfo.webglBuildCompression !== undefined ? buildInfo.webglBuildCompression : 'BROTLI';

    const updateBuildPath = evt => {
        const newValue = evt.target.value;
        setBuildInfo(info => ({ ...info, otherBuildPath: newValue }));
    };
    const updateCompression = evt => {
        const newValue = evt.target.value;
        setBuildInfo(info => ({ ...info, webglBuildCompression: newValue }));
    };
    const updateIncludeSymbols = evt => {
        const newValue = evt.target.checked;
        setBuildInfo(info => ({ ...info, includeWebglSymbols: newValue }));
    };
    return (
        <span style={{ marginLeft: '20px' }}>
            <p />
            <span>
                Build Path:
                <input
                    style={{ marginLeft: '10px' }}
                    type='text'
                    name='otherBuildPath'
                    value={buildPath}
                    onChange={updateBuildPath}
                />
            </span>
            <p />
            <WebGLSettingsForm
                includeSymbols={buildIncludeSymbols}
                onIncludeSymbolsChange={updateIncludeSymbols}
                compressionType={buildCompression}
                onCompressionChange={updateCompression}
            />
        </span>
    );
};

export default AdminForm;
