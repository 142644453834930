import React from 'react';
import styled from '@emotion/styled';

export const Downloader = styled.a`
    display: none;
`;

export const FileDownloader = props => {
    const { url, downloadRef } = props;
    return (
        <Downloader href={url} ref={downloadRef}>
            Download
        </Downloader>
    );
};
