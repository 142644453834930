import React from 'react';
import styled from '@emotion/styled';
import UnityLogo from '../../Assets/Icons/UnityLogoWhite.svg';

const AdminHeader = ({ children }) => (
    <Header>
        <a href='/' style={{ marginRight: 'auto' }}>
            <Logo />
            <ItemText style={{ float: 'right', paddingTop: '5px' }}>Back to app</ItemText>
        </a>
        {children}
    </Header>
);

const ItemText = styled.div`
    color: white;
    display: inline-block;
    margin-right: 15px;
`;
const Header = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    background: black;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 40px;
`;

const Logo = styled.div`
    width: 36px;
    height: 32px;
    display: inline-block;
    background-image: url(${UnityLogo});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
`;

export default AdminHeader;
