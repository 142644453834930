import React, { useState } from 'react';
import styled from '@emotion/styled';
import debounce from 'lodash.debounce';

import { useTranslation } from 'react-i18next';
import { MomentLandingLeft } from './Share/MomentLandingLeft';
import { MomentLandingRight } from './Share/MomentLandingRight';

import { SnapLandingLeft } from './Snap/SnapLandingLeft';
import { SnapLandingRight } from './Snap/SnapLandingRight';

import { landscapeTabletBreakpointPx } from '../../constants';
import { MenuModal } from './MenuModal';

import { PHOTOBOOTH_HIDDEN, MOMENTS_HIDDEN, CELEBRATION_ENABLED } from '../../environment';

export const MomentsContainer = styled.div`
    display: grid;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    @media (max-width: ${props => props.maxWidth}) {
        display: initial;
        overflow-y: scroll;
        overflow-x: hidden;
    }
`;

const ButtonHolder = styled.div`
    display: inline-grid;
    grid-template-columns: max-content;
    gap: 8px;
    color: #ffffff;
    weight: 700;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    margin-right: 32px;
    cursor: pointer;
    pointer-events: initial;
    z-index: 500;
    opacity: ${props => (props.active ? 1 : 0.4)};
`;

const LineHolder = styled.div`
    height: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Line = styled.div`
    display: block;
    width: 100%;
    height: ${props => (props.active ? '3px' : '1px')};
    background-color: #ffffff;
`;

const ButtonNav = styled.div`
    margin-top: 50px;
`;

const Navigation = ({ section, onSectionChange }) => {
    const { t } = useTranslation();

    return (
        <ButtonNav>
            <ButtonHolder onClick={() => onSectionChange('Snap')} active={section === 'Snap'}>
                <div>{t('celebration.navigator_01')}</div>
                <LineHolder>
                    <Line active={section === 'Snap'} />
                </LineHolder>
                <div>{t('celebration.navigator_snap')}</div>
            </ButtonHolder>
            <ButtonHolder onClick={() => onSectionChange('Share')} active={section === 'Share'}>
                <div>{t('celebration.navigator_02')}</div>
                <LineHolder>
                    <Line active={section === 'Share'} />
                </LineHolder>
                <div>{t('celebration.navigator_moments')}</div>
            </ButtonHolder>
        </ButtonNav>
    );
};

export const Moments = () => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const [momentLandingLeftTop, setMomentLandingLeftTop] = useState(0);
    const handleScroll = e => {
        if (!e.target) {
            return;
        }
        const winScroll = e.target.scrollTop;
        setScrollHeight(winScroll);
    };

    const [section, setSection] = useState(() => (PHOTOBOOTH_HIDDEN ? 'Share' : 'Snap'));

    const showNavigationMenu = !PHOTOBOOTH_HIDDEN && !MOMENTS_HIDDEN;
    return CELEBRATION_ENABLED ? (
        <MomentsContainer
            maxWidth={landscapeTabletBreakpointPx}
            onScroll={debounce(handleScroll, 100, {
                leading: true,
                maxWait: 100,
                trailing: true
            })}>
            {showNavigationMenu && (
                <MenuModal
                    currentSection={section}
                    goToSnap={() => setSection('Snap')}
                    goToMoments={() => setSection('Share')}
                />
            )}
            {section === 'Share' ? (
                <>
                    <MomentLandingLeft scrollHeight={scrollHeight} top={momentLandingLeftTop}>
                        {showNavigationMenu && <Navigation section={section} onSectionChange={setSection} />}
                    </MomentLandingLeft>
                    <MomentLandingRight setMomentLandingLeftTop={setMomentLandingLeftTop} />
                </>
            ) : (
                <>
                    <SnapLandingLeft scrollHeight={scrollHeight} top={momentLandingLeftTop}>
                        {showNavigationMenu && <Navigation section={section} onSectionChange={setSection} />}
                    </SnapLandingLeft>
                    <SnapLandingRight setMomentLandingLeftTop={setMomentLandingLeftTop} />
                </>
            )}
        </MomentsContainer>
    ) : null;
};
