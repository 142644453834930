import React, { useEffect, useRef, useState } from 'react';

// Dependencies
import debounce from 'lodash.debounce';

// Components
import { Box, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MomentsHeader } from '../../Header/Header';

// Variables
import { landscapeTabletBreakpoint } from '../../../constants';

// Assets
import { ReactComponent as Camera } from '../../../Assets/Icons/camera.svg';

// Styles
import { momentsStyles } from '../Styles/useStyles';

export const SnapLandingLeft = props => {
    const { scrollHeight, top, children } = props;
    const [visible, setVisible] = useState(false);
    const containerTop = top < 0 ? `${top}px` : '0px';
    const { t } = useTranslation();

    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    });

    const containerRef = useRef(null);
    useEffect(() => {
        if (containerRef.current == null) {
            return;
        }

        let shouldBeVisible;
        if (windowSize.width < landscapeTabletBreakpoint) {
            shouldBeVisible = scrollHeight > containerRef.current.clientHeight;
        } else {
            shouldBeVisible = windowSize.width > landscapeTabletBreakpoint;
        }
        if (visible !== shouldBeVisible) {
            setVisible(shouldBeVisible);
        }
    }, [windowSize.width, scrollHeight, visible]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () =>
            window.removeEventListener(
                'resize',
                debounce(handleResize, 100, {
                    leading: true,
                    maxWait: 100,
                    trailing: true
                })
            );
    }, []);

    return (
        <Box sx={momentsStyles.container(containerTop)} ref={containerRef}>
            <Box sx={momentsStyles.title}>
                <MomentsHeader />
            </Box>
            <Box sx={momentsStyles.bottomContainer}>
                <SvgIcon component={Camera} viewBox='0 0 32 32' sx={momentsStyles.sectionImg} alt={t('snap.title')} />
                <Box sx={momentsStyles.headline}>{t('snap.title')}</Box>
                <Box sx={momentsStyles.body}>{t('snap.description')}</Box>
                {children}
            </Box>
        </Box>
    );
};
