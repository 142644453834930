import {
    landscapeTabletBreakpointPx,
    mobileBreakpointPx,
    mobileBreakpointSmPx,
    tabletBreakpointPx
} from '../../../constants';
import { MOMENTS_COLOR_GRADIENT, PRIMARY_COLOR, TERTIARY_COLOR } from '../../../environment';

export const momentsStyles = {
    container: props => ({
        background: `${MOMENTS_COLOR_GRADIENT}`,
        display: 'flow-root',
        position: 'absolute',
        width: '50%',
        height: '100%',
        top: props.top,
        pointerEvents: 'none',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            height: 'auto',
            paddingBottom: '30px',
            width: '100%',
            position: 'inherit',
            marginTop: '-18px'
        }
    }),
    title: {
        width: '60%',
        margin: '0 auto',
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            marginLeft: '20px'
        },
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            width: '100%',
            marginLeft: '20px'
        }
    },
    downloadButton: {
        width: '175px',
        height: '52px',
        marginTop: '24px',
        fontFamily: 'CelebrationFontBold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '-0.03em',
        color: '#FFFFFF',
        backgroundColor: `${PRIMARY_COLOR}`,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: `${TERTIARY_COLOR}`
        }
    },
    bottomContainer: {
        display: 'grid',
        gridTemplateColumns: '100%',
        width: '60%',
        margin: '0 auto',
        marginTop: 'calc(30vh - 120px)',
        alignItems: 'center',
        justifyContent: 'center',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            padding: '20px',
            display: 'inherit',
            width: 'auto',
            marginTop: '100px'
        }
    },
    sectionImg: {
        width: 72,
        height: 72,
        color: '#FFFFFF',
        filter: 'drop-shadow(0 0 0.3rem rgba(255, 255, 255, 0.3))'
    },
    topRow: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        '& > svg': {
            position: 'absolute',
            top: '10px'
        }
    },
    headline: {
        fontFamily: 'CelebrationFontBold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '64px',
        lineHeight: '103px',
        paddingTop: '36px',
        color: '#FFFFFF',
        width: '800px',
        [`@media (max-width: 1600px)`]: {
            width: 'unset'
        },
        [`@media (max-width: ${tabletBreakpointPx})`]: {
            whiteSpace: 'nowrap',
            fontSize: '60px',
            paddingTop: '0px'
        },
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            whiteSpace: 'nowrap',
            height: '56px',
            fontSize: '36px !important',
            lineHeight: '52px'
        },
        [`@media (max-width: ${mobileBreakpointSmPx})`]: {
            whiteSpace: 'nowrap',
            fontSize: '24px !important',
            lineHeight: '36px'
        }
    },
    icon: {
        marginLeft: '310px',
        marginTop: '-155px',
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            marginLeft: 'auto',
            marginTop: '-115px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        [`@media (max-width: ${mobileBreakpointSmPx})`]: {
            width: '120px'
        }
    },
    body: {
        fontFamily: 'CelebrationFontRegular',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '26px',
        marginTop: '30px',
        //letterSpacing: '-0.03em',
        color: '#FFFFFF',
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            fontSize: '16px',
            lineHeight: '24px',
            width: '100%'
        }
    },
    buttons: {
        display: 'grid',
        pointerEvents: 'auto',
        gridTemplateColumns: '1fr 1fr'
    },
    qrCodeContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            display: 'none'
        }
    },
    qrCodeTooltip: {
        fontFamily: 'CelebrationFontRegular',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '-0.03em',
        color: '#FFFFFF',
        width: '100px',
        height: '38px',
        padding: '8px 12px 8px 12px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
        marginLeft: '34px'
    },
    qrCodeImage: {
        marginLeft: '20px'
    },
    rightArrow: {
        width: '0',
        height: '0',
        borderTop: '9px solid transparent',
        borderBottom: '9px solid transparent',
        borderLeft: '9px solid rgba(255, 255, 255, 0.1)',
        marginLeft: '112px',
        marginTop: '-28px'
    },
    stickyNavigationBar: {
        position: 'absolute',
        background: `${MOMENTS_COLOR_GRADIENT}`,
        height: '60px',
        width: '100%',
        zIndex: '1',
        top: '0px',
        [`@media (min-width: ${landscapeTabletBreakpointPx})`]: {
            display: 'none'
        }
    },
    stickyHeading: {
        position: 'absolute',
        top: '18px',
        left: '21px',
        fontFamily: 'CelebrationFontMedium',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFFFFF',
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            fontSize: '14px'
        }
    },
    stickyButton: {
        position: 'absolute',
        top: '12px',
        right: '17.64px',
        width: '155.36px',
        height: '36px',
        fontFamily: 'CelebrationFontBold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13.09px',
        lineHeight: '14.46px',
        color: '#FFFFFF',
        background: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.64)'
        }
    }
};
