import React, { useState, useEffect } from 'react';

// Dependencies
import { Box, IconButton, Modal } from '@mui/material';
import QRCode from 'qrcode';

// Variables
import { PRIMARY_COLOR, TERTIARY_COLOR } from '../../environment';
import { landscapeTabletBreakpointPx, mobileBreakpointPx } from '../../constants';

// SVG elements
import { ReactComponent as Close } from '../../Assets/Icons/close.svg';
import { ReactComponent as MenuBars } from '../../Assets/Icons/menu.svg';

//Images
import snap from '../../Assets/Icons/snap.png';
import moments from '../../Assets/Icons/moments.png';
import { useTranslation } from 'react-i18next';

// Styles
const menuModalStyles = {
    Menu: {
        position: 'absolute',
        top: '3.5%',
        right: '4.7%',
        color: 'white',
        pointerEvents: 'initial',
        zIndex: '999',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            right: '20px',
            top: '25px',
            position: 'relative'
        }
    },
    MenuIcon: {
        position: 'absolute',
        top: '0',
        right: '0',
        cursor: 'pointer',
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            right: '15px'
        }
    },
    MenuModalComponent: {
        position: 'absolute',
        width: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 1)',
        top: `0%`,
        left: `0%`,
        bottom: `0%`,
        right: `0%`,
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            overflowY: 'scroll',
            overflowX: 'hidden'
        }
    },
    MenuContentHolder: {
        margin: 'auto',
        width: '100%',
        maxWidth: '770px',
        display: 'grid',
        gap: '24px',
        '& h1': {
            margin: '0',
            fontSize: '24px',
            lineHeight: '32px'
        },
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            marginTop: '40px'
        }
    },
    MenuButtonHolder: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        textAlign: 'left',
        gap: '24px',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            gridTemplateColumns: '1fr',
            padding: '0 30px'
        }
    },
    MenuButton: {
        padding: '40px',
        borderRadius: '16px',
        backgroundColor: `rgba(255,255,255,0.08)`,
        textTransform: 'none',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: `${TERTIARY_COLOR}`
        },
        '&>p': {
            fontSize: '16px',
            lineHeight: '24px',
            marginTop: '4px'
        },
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            padding: '20px'
        }
    },
    MenuButtonSelected: {
        padding: '40px',
        borderRadius: '16px',
        backgroundColor: `${PRIMARY_COLOR}`,
        textTransform: 'none',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: `${TERTIARY_COLOR}`
        },
        '&>p': {
            fontSize: '16px',
            lineHeight: '24px',
            marginTop: '4px'
        },
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            padding: '20px'
        }
    },
    MenuOr: {
        display: 'grid',
        gridTemplateColumns: 'auto min-content auto',
        gap: '16px',
        color: 'rgba(255, 255, 255, 0.24)',
        '& .line': {
            height: '1px',
            backgroundColor: 'rgba(255, 255, 255, 0.24)',
            width: '100%'
        },
        '&>div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            display: 'none'
        }
    },
    MenuQR: {
        display: 'inline-grid',
        gridTemplateColumns: '75px auto',
        gap: '16px',
        textAlign: 'left',
        '&>img': {
            width: '100%'
        },
        '&>div': {
            alignSelf: 'center'
        },
        '& *': {
            margin: '0'
        },
        '& p': {
            marginTop: '8px'
        },
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            display: 'none'
        }
    },
    closeButton: {
        position: 'absolute',
        top: '4.3%',
        right: '4.5%',
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            right: '16px'
        },
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            right: '14px',
            top: '3.5%'
        }
    },
    title: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        width: '80%',
        margin: '0 auto',
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            marginLeft: '20px'
        },
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            width: '100%',
            marginLeft: '20px'
        }
    },
    MenuModalClass: {
        width: '100%',
        outline: 'none'
    },
    SectionImg: {
        filter: 'drop-shadow(0 0 0.3rem rgba(255, 255, 255, 0.3))',
        marginBottom: '16px',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            width: '45px',
            marginBottom: '8px'
        }
    }
};

export const MenuModal = props => {
    const [qrCodeSource, setQrCodeSource] = useState(null);
    const [open, setOpen] = useState(false);
    const { currentSection, goToSnap, goToMoments } = props;

    const { t } = useTranslation();

    useEffect(() => {
        QRCode.toDataURL(window.location.href).then(data => {
            setQrCodeSource(data);
        });
    }, []);

    const goTo = S => {
        if (S === 'share') goToMoments();
        if (S === 'snap') goToSnap();
        setOpen(false);
    };

    return (
        <Box component='menu' sx={menuModalStyles.Menu}>
            {!open && (
                <Box sx={menuModalStyles.MenuIcon} onClick={() => setOpen(true)}>
                    {' '}
                    <MenuBars style={{ height: '24px', width: '24px' }} />{' '}
                </Box>
            )}
            <Modal open={open} sx={menuModalStyles.MenuModalComponent}>
                <Box sx={menuModalStyles.MenuModalClass}>
                    <IconButton sx={menuModalStyles.closeButton} onClick={() => setOpen(false)}>
                        <Close />
                    </IconButton>
                    <Box sx={menuModalStyles.MenuContentHolder}>
                        <Box>
                            <h1>{t('celebration.menu_modal.title')}</h1>
                            <p>{t('celebration.menu_modal.text')}</p>
                        </Box>
                        <Box sx={menuModalStyles.MenuButtonHolder}>
                            <Box
                                sx={
                                    currentSection === 'Snap'
                                        ? menuModalStyles.MenuButtonSelected
                                        : menuModalStyles.MenuButton
                                }
                                onClick={() => goTo('snap')}>
                                <img src={snap} alt={t('snap.title')} />
                                <h1>{t('snap.title')}</h1>
                                <p>{t('celebration.menu_modal.snap_description')}</p>
                            </Box>
                            <Box
                                sx={
                                    currentSection === 'Share'
                                        ? menuModalStyles.MenuButtonSelected
                                        : menuModalStyles.MenuButton
                                }
                                onClick={() => goTo('share')}>
                                <img src={moments} alt={t('moments.title')} />
                                <h1>{t('moments.title')}</h1>
                                <p>{t('celebration.menu_modal.moments_description')}</p>
                            </Box>
                        </Box>
                        <Box sx={menuModalStyles.MenuOr}>
                            <Box>
                                <Box className='line' />
                            </Box>
                            <Box>{t('celebration.menu_modal.or_word')}</Box>
                            <Box>
                                <Box className='line' />
                            </Box>
                        </Box>
                        <Box>
                            <Box sx={menuModalStyles.MenuQR}>
                                <img src={qrCodeSource} alt={t('celebration.menu_modal.qr_code_alt')} />
                                <Box>
                                    <h3>{t('celebration.menu_modal.mobile_website_title')}</h3>
                                    <p>{t('celebration.menu_modal.mobile_website_message')}</p>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};
