import { createTheme } from '@mui/material';
import {
    LOGIN_BACKGROUND_COLOR_GRADIENT,
    LOGIN_BUTTON_COLOR,
    MOMENTS_COLOR_BACKGROUND,
    MOMENTS_COLOR_GRADIENT,
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    TERTIARY_COLOR
} from '../../environment';
import {
    desktopLargeBreakpoint,
    landscapeTabletBreakpoint,
    mobileBreakpoint,
    mobileBreakpointSm,
    tabletBreakpoint
} from '../constants/styles';

export default createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: mobileBreakpointSm,
            md: mobileBreakpoint,
            lg: tabletBreakpoint,
            xl: landscapeTabletBreakpoint,
            xxl: desktopLargeBreakpoint
        }
    },
    palette: {
        primary: {
            main: PRIMARY_COLOR
        },
        secondary: {
            main: SECONDARY_COLOR
        },
        tertiary: {
            main: TERTIARY_COLOR
        },
        momentsGradient: {
            main: MOMENTS_COLOR_GRADIENT
        },
        momentsBg: {
            main: MOMENTS_COLOR_BACKGROUND
        },
        loginButton: {
            main: LOGIN_BUTTON_COLOR
        },
        loginGradient: {
            main: LOGIN_BACKGROUND_COLOR_GRADIENT
        }
    }
});
