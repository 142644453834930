import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import debounce from 'lodash.debounce';
import { engineName } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { VideoTile } from './VideoTile';
import { ReactComponent as VerticalStroke } from '../../../Assets/Icons/vertical_stroke.svg';
import { landscapeTabletBreakpoint, landscapeTabletBreakpointPx, tabletBreakpointPx } from '../../../constants';
import { NarrowFooter, Footer } from '../../Header/Header';
import { PRIMARY_COLOR, SERVER_ENDPOINT } from '../../../environment';

const momentLandingRightStyles = {
    sidebar: {
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            display: 'none'
        }
    },
    sidebarTextGroup: {
        transform: 'rotate(-90deg)',
        color: 'rgba(255, 255, 255, 0.5)',
        position: 'absolute',
        fontFamily: 'ClientFontMedium',
        fontStyle: 'normal',
        fontSize: '12px',
        lineHeight: '12px',
        letterSpacing: '4.5px',
        textTransform: 'uppercase',
        zIndex: '999',
        opacity: '0.60',
        height: '10px',
        right: '80px',
        bottom: '140px',
        '@media screen and (max-width: 1024px)': {
            right: '40px'
        }
    },
    sidebarText: {
        color: 'rgba(255, 255, 255, 0.5)',
        position: 'absolute',
        fontFamily: 'ClientFontMedium',
        fontStyle: 'normal',
        fontSize: '12px',
        lineHeight: '12px',
        letterSpacing: '4.5px',
        textTransform: 'uppercase',
        zIndex: '999',
        opacity: '0.70',
        marginLeft: '20px',
        width: '200px',
        pointerEvents: 'none'
    },
    buttonGroup: {
        position: 'absolute',
        zIndex: '999',
        bottom: '50vh',
        right: '60px',
        '@media screen and (max-width: 1024px)': {
            right: '15px'
        }
    },
    verticalStroke: {
        position: 'absolute',
        zIndex: '999',
        transform: 'rotate(-270deg)',
        transformOrigin: 'top right',
        opacity: '0.70',
        marginTop: '7px'
    },
    modal: {
        background: 'white',
        position: 'absolute',
        right: '-100vw',
        left: '0',
        top: '0',
        bottom: '0',
        opacity: '0.8',
        overflow: 'visible'
    },
    content: {
        background: '#0d0d0d',
        overflowY: 'scroll',
        overflowX: 'hidden',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            overflow: 'hidden'
        }
    },
    buttonColor: {
        color: 'rgba(255, 255, 255, 0.15)',
        '&.Mui-selected': {
            color: `${PRIMARY_COLOR}`
        }
    },
    buttonSize: {
        width: '10px',
        height: '10px'
    },
    videoTiles: {
        minHeight: '100vh',
        [`@media (min-width: ${landscapeTabletBreakpointPx})`]: {
            width: '50%',
            position: 'relative',
            left: 'calc(50vw)'
        }
    },
    footer: {
        width: '100vw',
        backgroundColor: 'black',
        opacity: '0.9',
        [`@media (max-height: ${tabletBreakpointPx})`]: {
            display: 'none'
        },
        [`@media (max-width: ${tabletBreakpointPx})`]: {
            display: 'block'
        }
    }
};

export const MomentLandingRight = props => {
    const [data, updateData] = useState([]);
    const [scrollPercent, setScrollPercent] = useState(0);
    const { setMomentLandingLeftTop } = props;
    const { t } = useTranslation();

    const isWebKit = engineName.toLowerCase() === 'webkit';

    useEffect(() => {
        const getData = () => {
            fetch(`${SERVER_ENDPOINT}/moments`, {
                method: 'GET'
            })
                .then(response => response.json())
                .then(responseData => updateData(responseData.moments))
                .catch(error => console.log(error));
        };
        getData();
    }, []);

    const [position, setPosition] = React.useState(0);
    const scrollableContent = useRef(null);

    const handlePosition = (event, newPosition) => {
        if (newPosition !== null && data) {
            const scrollPosition = (newPosition * scrollableContent.current.scrollHeight) / data.length;
            scrollableContent.current.scroll({ top: scrollPosition, left: 0, behavior: 'smooth' });
        }
    };

    const videoTilesRef = useRef(null);
    const handleScroll = e => {
        if (!e.target || !data) {
            return;
        }
        const winScroll = e.target.scrollTop;
        const height = e.target.scrollHeight - e.target.clientHeight;

        if (videoTilesRef) {
            setMomentLandingLeftTop(videoTilesRef.current.getBoundingClientRect().bottom - e.target.clientHeight);
        }

        const scrolled = winScroll / height;
        setScrollPercent(scrolled);
        let newScrollPos = Math.floor(scrolled * data.length);
        newScrollPos = newScrollPos > data.length - 1 ? data.length - 1 : newScrollPos;
        setPosition(newScrollPos);
    };

    const footer = window.innerWidth > landscapeTabletBreakpoint ? <Footer /> : <NarrowFooter />;

    if (!data) {
        return null;
    }

    return (
        <Box
            sx={momentLandingRightStyles.content}
            onScroll={debounce(handleScroll, 100, {
                leading: true,
                maxWait: 100,
                trailing: true
            })}
            ref={scrollableContent}>
            <Box sx={momentLandingRightStyles.sidebar}>
                <Box sx={momentLandingRightStyles.sidebarTextGroup}>
                    <Box sx={momentLandingRightStyles.verticalStroke}>
                        <VerticalStroke />
                    </Box>
                    <Box sx={momentLandingRightStyles.sidebarText}>{t('moments.scroll_down_label')}</Box>
                </Box>
                <Box sx={momentLandingRightStyles.buttonGroup}>
                    <ToggleButtonGroup orientation='vertical' value={position} exclusive onChange={handlePosition}>
                        {data.map((item, index) => (
                            <ToggleButton key={item.momentID} value={index} sx={momentLandingRightStyles.buttonColor}>
                                <FiberManualRecord sx={momentLandingRightStyles.buttonSize} />
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Box>
            </Box>
            <Box sx={momentLandingRightStyles.videoTiles} ref={videoTilesRef}>
                {data.map((item, index) => (
                    <VideoTile
                        key={item.momentID}
                        index={index + 1}
                        momentId={item.momentID}
                        descriptionLocId={item.description}
                        videoSrc={isWebKit ? item.previewVideoUrlSafari : item.previewVideoUrl}
                        posterSrc={item.previewPosterUrl}
                        scrollPercent={scrollPercent}
                        numberOfTiles={data.length}
                    />
                ))}
            </Box>
            <Box sx={momentLandingRightStyles.footer}>{footer}</Box>
        </Box>
    );
};
