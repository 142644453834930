import React, { useState } from 'react';

// Dependencies
import { Box, Button, IconButton, Modal, SvgIcon } from '@mui/material';

// Components
import { useTranslation } from 'react-i18next';
import { SnapModal } from './SnapModal';

// Variables
import {
    landscapeTabletBreakpoint,
    landscapeTabletBreakpointPx,
    mobileBreakpointPx,
    tabletBreakpointPx
} from '../../../constants';
import { NarrowFooter, Footer } from '../../Header/Header';
import { PRIMARY_COLOR, TERTIARY_COLOR, MOMENTS_COLOR_BACKGROUND } from '../../../environment';

// Assets
import { ReactComponent as Close } from '../../../Assets/Icons/close.svg';
import { ReactComponent as Camera } from '../../../Assets/Icons/camera.svg';
import videoSrc from '../../../Assets/Dynamic/snapVideo.mp4';

// Styles
const snapLandingRightStyles = {
    content: {
        background: `${MOMENTS_COLOR_BACKGROUND}`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            overflow: 'hidden'
        }
    },
    videoTiles: {
        minHeight: 'calc(100vh - 72px)',
        width: '50%',
        position: 'relative',
        left: 'calc(50vw)',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            width: '100%',
            left: 'inherit',
            minHeight: 'inherit'
        }
    },
    videoHolder: {
        minHeight: 'calc(100vh - 72px)',
        width: '100%',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            width: '100%',
            minHeight: 'inherit',
            display: 'block'
        }
    },
    previewVideo: {
        position: 'inherit',
        '& video': {
            width: '75%',
            maxWidth: '1200px',
            margin: '0 auto',
            marginTop: '-100px',
            [`@media (max-width: ${landscapeTabletBreakpoint})`]: {
                height: '380px',
                marginTop: '0px'
            },
            [`@media (max-width: ${tabletBreakpointPx})`]: {
                width: '100%',
                marginTop: '0px'
            }
        }
    },
    snapButton: {
        width: '240px',
        height: '60px',
        margin: 'auto',
        marginTop: '8px',
        fontFamily: 'CelebrationFontBold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '20px',
        letterSpacing: '-0.00em',
        color: '#FFFFFF',
        backgroundColor: `${PRIMARY_COLOR}`,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: `${TERTIARY_COLOR}`
        },
        [`@media (max-width: ${tabletBreakpointPx})`]: {
            marginBottom: '80px'
        }
    },
    closeButton: {
        position: 'absolute',
        top: '3%',
        right: '12%',
        zIndex: 999,
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            right: '15px'
        }
    },
    footer: {
        width: '100vw',
        backgroundColor: 'black',
        opacity: '0.9',
        [`@media (max-height: ${tabletBreakpointPx})`]: {
            display: 'none'
        },
        [`@media (max-width: ${tabletBreakpointPx})`]: {
            display: 'block'
        }
    },
    icon: {
        height: 32,
        width: 32,
        color: '#FFFFFF'
    }
};

export const SnapLandingRight = () => {
    const FooterEl = window.innerWidth > landscapeTabletBreakpoint ? <Footer /> : <NarrowFooter />;
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <Box sx={snapLandingRightStyles.content}>
            <Box sx={snapLandingRightStyles.videoTiles}>
                <Box sx={snapLandingRightStyles.videoHolder}>
                    <Box>
                        <Box sx={snapLandingRightStyles.previewVideo}>
                            <video loop preload='auto' muted autoPlay playsInline>
                                <source src={videoSrc} type='video/mp4' />
                                <track src='' kind='captions' label='english_captions' />
                            </video>
                        </Box>
                        <Button
                            variant='contained'
                            sx={snapLandingRightStyles.snapButton}
                            startIcon={
                                <SvgIcon component={Camera} viewBox='0 0 32 32' sx={snapLandingRightStyles.icon} />
                            }
                            onClick={() => setOpen(true)}>
                            {t('snap.take_snap_label')}
                        </Button>
                    </Box>
                    <Modal open={open}>
                        <>
                            <SnapModal />
                            <IconButton sx={snapLandingRightStyles.closeButton} onClick={() => setOpen(false)}>
                                <Close />
                            </IconButton>
                        </>
                    </Modal>
                </Box>
            </Box>
            <Box sx={snapLandingRightStyles.footer}>{FooterEl}</Box>
        </Box>
    );
};
