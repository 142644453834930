import React, { useEffect, useMemo, useRef, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Box, Button, Modal } from '@mui/material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { AssetGallery } from './AssetGallery';
import { ReactComponent as RightArrow } from '../../../Assets/Icons/right_arrow.svg';
import LightSquare from '../../../Assets/light-square.gif';
import LightRectangle from '../../../Assets/light-rectangle.gif';
import {
    landscapeTabletBreakpointPx,
    mobileBreakpointPx,
    mobileBreakpointSmPx,
    tabletBreakpointPx
} from '../../../constants';
import { PRIMARY_COLOR } from '../../../environment';

const videoTileStyles = {
    tile: {
        position: 'relative',
        color: 'white',
        height: '100vh',
        minHeight: '900px',
        [`@media (min-width: ${tabletBreakpointPx})`]: {
            height: '100vh',
            minHeight: 'none'
        }
    },
    lightGroup: {
        position: 'relative',
        paddingTop: '14vh',
        marginLeft: '17%',
        width: '390px',
        height: '550px',
        '@media screen and (min-height: 1024px)': {
            marginTop: '15% !important'
        },
        '@media screen and (max-height: 670px)': {
            paddingTop: '0'
        },
        '@media screen and (max-width: 1024px)': {
            margin: '0 auto',
            paddingTop: '80px'
        }
    },
    previewVideo: {
        marginTop: '-500px',
        marginLeft: '21%',
        position: 'inherit',
        '@media screen and (max-width: 1024px)': {
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center'
        },
        '& video': {
            width: 'auto',
            height: '440px',
            [`@media (max-width: ${mobileBreakpointPx})`]: {
                height: '380px'
            }
        },
        [`@media (max-width: ${tabletBreakpointPx})`]: {
            marginTop: '100px'
        }
    },
    heading: {
        display: 'flex',
        marginLeft: '15%',
        marginTop: '60px',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            marginLeft: '25%',
            marginTop: '100px',
            width: '100%'
        },
        [`@media (max-width: ${tabletBreakpointPx})`]: {
            marginLeft: 'unset',
            justifyContent: 'center'
        }
    },
    headingText: {
        fontFamily: 'ClientFontBold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '34px',
        lineHeight: '36px',
        width: '380px',
        color: 'white',
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            width: '310px'
        },
        [`@media (max-width: ${mobileBreakpointSmPx})`]: {
            fontSize: '30px',
            lineHeight: '32px',
            width: '245px'
        }
    },
    modalHeadingText: {},
    number: {
        fontFamily: 'ClientFontBold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '29px',
        color: 'white',
        width: '60px',
        [`@media (max-width: ${tabletBreakpointPx})`]: {
            width: '40px'
        }
    },
    modalLink: {
        '&.MuiButton-root': {
            fontFamily: 'ClientFontMedium',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '19px',
            color: `${PRIMARY_COLOR}`,
            textTransform: 'none',
            marginTop: '10px',
            marginLeft: '-8px'
        }
    }
};

function getScrollValue(index, number, scrollValue) {
    const min = (index - 1) / number;
    const max = index / number;

    let offset;
    if (index === 0) {
        offset = 0.0;
    } else if (index === number) {
        offset = 1.0;
    } else {
        offset = 0.5;
    }

    const clampedValue = Math.min(max, Math.max(min, scrollValue));
    return (clampedValue - min) / (max - min) - offset;
}

const LightBox = styled.img`
    top: ${props => props.top};
    left: ${props => props.left};
    bottom: ${props => props.bottom};
    right: ${props => props.right};
    padding: ${props => props.padding};
    position: ${props => props.position};
    opacity: ${props => props.opacity};
    filter: ${props => props.filter};
`;

const LightGroup = props => {
    const { index } = props;
    const isEven = index % 2 === 0;
    return (
        <>
            {isEven && (
                <>
                    <LightBox
                        filter='blur(0.85px)'
                        position='absolute'
                        opacity='0.3'
                        top='130px'
                        left=''
                        bottom='1px'
                        right='-30px'
                        width='125px'
                        height='125px'
                        src={LightSquare}
                        alt=''
                    />
                    <LightBox
                        filter='blur(0.85px)'
                        position='absolute'
                        opacity='0.3'
                        top='250px'
                        left='20px'
                        bottom='1px'
                        right='0px'
                        width='125px'
                        height='125px'
                        src={LightSquare}
                        alt=''
                    />
                    <LightBox
                        filter='blur(0.85px)'
                        position='absolute'
                        opacity='0.3'
                        top='420px'
                        left=''
                        bottom='1px'
                        right='0px'
                        width='125px'
                        height='125px'
                        src={LightSquare}
                        alt=''
                    />
                </>
            )}
            {!isEven && (
                <>
                    <LightBox
                        filter='blur(0.85px)'
                        position='absolute'
                        top=''
                        left=''
                        bottom='40px'
                        right='-30px'
                        opacity='0.3'
                        width='125px'
                        height='125px'
                        src={LightSquare}
                        alt=''
                    />
                    <LightBox
                        filter='blur(0.85px)'
                        position='absolute'
                        top='150px'
                        left='0px'
                        bottom=''
                        right=''
                        opacity='0.3'
                        width='125px'
                        height='250px'
                        src={LightRectangle}
                        alt=''
                    />
                </>
            )}
        </>
    );
};

export const VideoTile = props => {
    const videoReference = useRef(null);
    const [visible, setVisible] = useState(false);
    const { index, videoSrc, posterSrc, numberOfTiles, descriptionLocId, scrollPercent, momentId } = props;
    const scrollValue = getScrollValue(index, numberOfTiles, scrollPercent);
    const { t } = useTranslation();

    const videoType = useMemo(() => (new URL(videoSrc).pathname.endsWith('.webm') ? 'video/webm' : 'video/mp4'), [
        videoSrc
    ]);

    useEffect(() => {
        const player = videoReference.current.children[0];
        if (!player) {
            return;
        }
        if (visible) {
            player.controls = false;
            player.playsinline = true;
            player.muted = true;
            // some browsers seem to fail to set the muted tag correctly... so lets be super sure
            player.setAttribute('muted', '');

            setTimeout(() => {
                const promise = player.play();
                if (promise.then) {
                    promise
                        .then(() => {})
                        .catch(error => {
                            console.log(error);
                            console.log('Unable to play video.');
                        });
                }
            });
        } else if (player.play) {
            player.pause();
        }
    }, [visible]);

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const videoOffset = -530;

    // const lightGroupParallaxFactor = 50;
    // const videoParallaxFactor = 100;
    // const textParallaxFactor = 100;

    const lightGroupParallaxFactor = 0;
    const videoParallaxFactor = 0;
    const textParallaxFactor = 0;

    return (
        <Box sx={videoTileStyles.tile}>
            <Box sx={videoTileStyles.lightGroup} style={{ marginTop: `${scrollValue * lightGroupParallaxFactor}px` }}>
                <LightGroup index={index} />
            </Box>
            <Box
                sx={videoTileStyles.previewVideo}
                style={{ marginTop: `${videoOffset - scrollValue * videoParallaxFactor}px` }}>
                <VisibilitySensor delayedCall onChange={isVisible => setVisible(isVisible)}>
                    {/* Fix for video not autoplaying on mobile: */}
                    {/* https://medium.com/@BoltAssaults/autoplay-muted-html5-video-safari-ios-10-in-react-673ae50ba1f5 */}
                    <Box
                        ref={videoReference}
                        dangerouslySetInnerHTML={{
                            __html: `
                                <video
                                  loop
                                  muted
                                  playsInline
                                  preload='auto'
                                  poster=${posterSrc}
                                >
                                <source src=${videoSrc} type=${videoType} />
                                </video>`
                        }}
                    />
                </VisibilitySensor>
            </Box>
            <Box style={{ marginTop: `${scrollValue * textParallaxFactor}px` }}>
                <Box sx={videoTileStyles.heading}>
                    <Box sx={videoTileStyles.number}>#{index}</Box>
                    <Box sx={videoTileStyles.headingText}>
                        {t(descriptionLocId)}
                        <br />
                        <Button sx={videoTileStyles.modalLink} onClick={handleOpen}>
                            {t('moments.view_moments_label')}&nbsp;
                            <RightArrow fill={PRIMARY_COLOR} />
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Modal open={open}>
                <AssetGallery index={index} momentId={momentId} handleClose={handleClose} styles={videoTileStyles} />
            </Modal>
        </Box>
    );
};

export default VideoTile;
