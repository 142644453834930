import React from 'react';
import styled from '@emotion/styled';
import AdminAuth from './AdminAuth';
import AdminHeader from './AdminHeader';
import { useDetectLogout } from '../../Hooks/useDetectLogout';

const Wrapper = styled.div`
    background-color: #18434a;
    box-sizing: border-box;
    padding: 40px;
    color: #f3f3f3;
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const Admin = ({ location }) => {
    const { loggingOut } = useDetectLogout();

    if (loggingOut) {
        return null;
    }

    return (
        <Wrapper>
            <AdminHeader />
            <AdminAuth location={location} />
        </Wrapper>
    );
};

export default Admin;
