import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Security, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { ThemeProvider } from '@mui/material/styles';
import Admin from './Admin/AdminWrapper';
import Logout from './Auth/Logout';
import { LoginCallbackWithError } from './Auth/LoginCallbackWithError';
import { LoginPage } from './Auth/Login';
import { oktaConfig, CALLBACK_PATH } from '../oktaConfig';
import { Moments } from './Moments/Moments';
import muiTheme from '../Utils/styles/muiTheme';
import { Box } from '@mui/material';
import { rootStyles } from './Root.style';

const authClient = new OktaAuth(oktaConfig);

const Root = () => {
    const history = useHistory();
    const showLoginPage = async () => {
        history.push('/login');
    };
    const rootPath = '/';

    // In the new version of Okta, in order to support token auto renew, we need to have the service running in the
    // background.
    // https://github.com/okta/okta-auth-js#running-as-a-service
    useEffect(() => {
        authClient.start();

        return () => {
            authClient.stop();
        };
    }, []);

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    return (
        <ThemeProvider theme={muiTheme}>
            <Security oktaAuth={authClient} onAuthRequired={showLoginPage} restoreOriginalUri={restoreOriginalUri}>
                <Box sx={rootStyles.container}>
                    <Switch>
                        <Route path={CALLBACK_PATH} component={LoginCallbackWithError} />
                        <Route path='/login' component={LoginPage} />
                        <SecureRoute exact path='/admin' component={Admin} />
                        <Route exact path={rootPath} component={Moments} />
                        <Route path='/logout' component={Logout} />
                        <Redirect path='*' to={rootPath} />
                    </Switch>
                </Box>
            </Security>
        </ThemeProvider>
    );
};

export default Root;
