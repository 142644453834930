export const rootStyles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media screen and (orientation: portrait)': {
            height: {
                xs: '100vh',
                lg: '100vh'
            }
        },
        '@media screen and (orientation: landscape)': {
            height: {
                xs: '100vh',
                xl: '100vh'
            }
        }
    }
};
