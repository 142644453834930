import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import { FiberManualRecord } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AssetRatios } from '../../../Assets/Icons/asset_ratios.svg';
import { ReactComponent as Close } from '../../../Assets/Icons/close.svg';
import { ReactComponent as Download } from '../../../Assets/Icons/download.svg';
import { ReactComponent as Checkmark } from '../../../Assets/Icons/checkmark.svg';
import { FileDownloader } from '../FileDownloader';
import { landscapeTabletBreakpointPx, mobileBreakpointPx, mobileBreakpointSmPx } from '../../../constants';
import { PRIMARY_COLOR, TERTIARY_COLOR, SERVER_ENDPOINT } from '../../../environment';

const assetGalleryStyles = {
    container: {
        position: 'absolute',
        width: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 1)',
        top: `0%`,
        left: `0%`,
        bottom: `0%`,
        right: `0%`,
        color: 'white',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            overflowY: 'scroll',
            overflowX: 'hidden'
        }
    },
    modalContainer: {
        margin: '0 auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    closeButton: {
        position: 'absolute',
        top: '3%',
        right: '12%',
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            right: '15px'
        }
    },
    selected: {
        height: '40vh',
        width: '40vw',
        position: 'absolute',
        left: '28%',
        top: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '220px',
        minWidth: '200px',
        maxHeight: '400px !important',
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%'
        },
        '& video': {
            maxWidth: '100%',
            maxHeight: '100%'
        },
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            left: '0px',
            right: '0px',
            width: '80vw',
            margin: '0 auto',
            textAlign: 'center',
            maxWidth: '450px',
            paddingTop: '7vh'
        }
    },
    buttonGroup: {
        display: 'none',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            display: 'inherit',
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            margin: '0 auto'
        }
    },
    imageContainer: {
        display: 'grid',
        gridTemplateRows: 'auto',
        height: '80px',
        rowGap: '40px',
        position: 'absolute',
        top: '20px',
        left: '15%',
        textAlign: 'center',
        '@media screen and (max-width: 1550px)': {
            left: '10%'
        }
    },
    previewHeading: {
        position: 'absolute',
        left: '15.35%',
        fontFamily: 'ClientFontRegular',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: 'rgba(150, 150, 150, 1)',
        marginTop: '-40px',
        '@media screen and (max-width: 1550px)': {
            left: '11%'
        }
    },
    previewThumbnails: {
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            display: 'none'
        }
    },
    imageContent: {
        display: 'inherit',
        position: 'absolute',
        right: '0',
        top: '30%',
        width: '40%',
        height: '100vh',
        paddingRight: '30px',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            position: 'inherit',
            margin: '0 auto',
            left: '0',
            right: '0',
            top: '55vh',
            width: '90%',
            height: '42vh',
            marginLeft: '0 auto',
            paddingRight: '0',
            maxWidth: '400px'
        },
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            width: '290px'
        },
        [`@media (max-width: ${mobileBreakpointSmPx})`]: {
            width: '250px'
        }
    },
    previewGallery: {
        width: '60%',
        height: '100vh',
        position: 'relative',
        top: '30%',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            position: 'inherit',
            margin: '0 auto',
            left: '0',
            right: '0',
            top: '0',
            width: '80%',
            height: '55vh',
            textAlign: 'center'
        }
    },
    heading: {
        display: 'block',
        gridTemplateColumns: '1fr auto',
        width: 'max-content',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            marginTop: '3vw'
        }
    },
    headingText: {
        fontFamily: 'ClientFontBold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '42.3836px',
        lineHeight: '45px',
        width: '343px',
        color: 'white',
        float: 'left',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            fontSize: '30px',
            lineHeight: '34px'
        },
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            width: '260px'
        },
        [`@media (max-width: ${mobileBreakpointSmPx})`]: {
            fontSize: '24px',
            lineHeight: '28px',
            width: '230px'
        }
    },
    number: {
        marginRight: '32px',
        fontFamily: 'ClientFontBold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '29px',
        color: 'white',
        width: '32px',
        float: 'left',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            width: 'inherit'
        }
    },
    momentContent: {
        marginLeft: '64px',
        marginTop: '32px',
        fontFamily: 'ClientFontRegular',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: 'rgba(150, 150, 150, 1)',
        width: '340px',
        clear: 'both',
        paddingTop: '20px',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            clear: 'both',
            paddingTop: '20px',
            marginLeft: '55px'
        },
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            width: '260px'
        },
        [`@media (max-width: ${mobileBreakpointSmPx})`]: {
            width: '230px'
        }
    },
    momentSizes: {
        marginLeft: '64px',
        marginBottom: '32px',
        marginTop: '20px',
        width: '340px',
        height: '50px',
        [`@media (max-width: ${landscapeTabletBreakpointPx})`]: {
            marginTop: '12px',
            marginBottom: '18px',
            height: '45px'
        },
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            marginLeft: '55px',
            marginTop: '12px',
            marginBottom: '18px'
        }
    },
    buttonColor: {
        color: 'rgba(255, 255, 255, 0.15)',
        '&.Mui-selected': {
            color: `${PRIMARY_COLOR}`
        }
    },
    buttonSize: {
        width: '10px',
        height: '10px'
    },
    downloadButtonContainer: {
        marginLeft: '64px',
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            marginLeft: '55px',
            paddingBottom: '50px'
        }
    },
    downloadButton: {
        width: '263px',
        height: '44px',
        fontFamily: 'ClientFontBold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '-0.03em',
        color: 'white',
        background: `${PRIMARY_COLOR}`,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: `${TERTIARY_COLOR}`
        },
        [`@media (max-width: ${mobileBreakpointPx})`]: {
            width: '200px'
        }
    }
};

const AssetPreviewThumbnail = props => {
    const { selected, index, asset, clickHandler } = props;
    const { type, ratio, srcUrl } = asset;
    const isVideo = type === 'video';
    const isImage = type === 'image';

    const width = ratio === '1:1' ? '46px' : '66px';
    const height = ratio === '1:1' ? '62px' : '44px';
    const paddingBottom = ratio === '1:1' ? '0px 10px 0px 10px' : '10px';

    return (
        <Box
            style={{
                borderBottom: selected ? `4px solid ${PRIMARY_COLOR}` : '4px solid rgba(255,255,255,0)',
                width: '66px'
            }}>
            {isImage && (
                <img
                    style={{ width, height, paddingBottom }}
                    key={index}
                    src={srcUrl}
                    alt=''
                    onClick={() => clickHandler(index)}
                    onKeyDown={() => clickHandler(index)}
                />
            )}
            {isVideo && (
                <video style={{ width, height, paddingBottom }} key={index} onClick={() => clickHandler(index)}>
                    <source src={srcUrl} type='video/mp4' />
                    <track src='' kind='captions' label='english_captions' />
                </video>
            )}
        </Box>
    );
};

const AssetPreview = props => {
    const { asset } = props;
    const { type, srcUrl } = asset;
    const isVideo = type === 'video';
    const isImage = type === 'image';

    const videoRef = useRef();

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load();
        }
    }, [srcUrl]);

    return (
        <>
            {isImage && <img src={srcUrl} alt='Selected' />}
            {isVideo && (
                <video autoPlay ref={videoRef} muted controls loop>
                    <source src={srcUrl} type='video/mp4' />
                </video>
            )}
        </>
    );
};

function MomentContents(previewAssets, t) {
    let videos = 0;
    let images = 0;
    for (let index = 0; index < previewAssets.length; index++) {
        const { type } = previewAssets[index];
        switch (type) {
            case 'video':
                videos++;
                break;
            case 'image':
                images++;
                break;
            default:
                console.log('Unsupported asset type.');
                break;
        }
    }

    let locKey = 'moments.assets.video_and_image';
    if (images === 0) {
        locKey = 'moments.assets.only_video';
    } else if (videos === 0) {
        locKey = 'moments.assets.only_image';
    }

    return t(locKey, { imageCount: images, videoCount: videos });
}

export const AssetGallery = props => {
    const [modalReady, updateModalReady] = useState(false);
    const [assetPreviews, setAssetPreviews] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState();
    const [description, setDescription] = useState();
    const [downloadAssetsURL, setDownloadAssetsURL] = useState('');
    const [isDownloaded, setIsDownloaded] = useState(false);
    const { momentId, index, handleClose } = props;

    const fileDownloader = useRef(null);

    const { t } = useTranslation();

    useEffect(() => {
        const getModalData = () => {
            const endpoint = `${SERVER_ENDPOINT}/moments/${momentId}`;
            fetch(endpoint, {
                method: 'GET'
            })
                .then(response => response.json())
                .then(data => {
                    setAssetPreviews(data.assets);
                    setDescription(data.description);
                    updateModalReady(true);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        getModalData();
    }, [momentId]);

    useEffect(() => {
        if (assetPreviews.length > 0) {
            setSelectedAsset(assetPreviews[0]);
        }
    }, [assetPreviews]);

    const handleNewAssetSelected = assetIndex => {
        setSelectedAsset(assetPreviews[assetIndex]);
    };

    const downloadAllAssets = () => {
        const endpoint = `${SERVER_ENDPOINT}/moments/moment-url/${momentId}`;
        fetch(endpoint, {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => {
                setDownloadAssetsURL(data.momentURL);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleToggleChange = (event, value) => {
        setSelectedAsset(value);
    };

    useEffect(() => {
        if (downloadAssetsURL) {
            fileDownloader.current.click();
            setIsDownloaded(true);
            setDownloadAssetsURL('');
        }
    }, [downloadAssetsURL]);

    const downloadEndIcon = isDownloaded ? (
        <Checkmark style={{ height: '14px', width: '17px' }} />
    ) : (
        <Download style={{ height: '14px', width: '17px' }} />
    );

    // Swipe functions
    const SwipeTolerance = 10;
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);

    const handleTouchStart = e => setTouchStart(e.targetTouches[0].clientX);
    const handleTouchMove = e => setTouchEnd(e.targetTouches[0].clientX);
    const handleTouchEnd = () => {
        const currentIndex = assetPreviews.indexOf(selectedAsset);
        if (touchStart - touchEnd > SwipeTolerance) {
            const Next = currentIndex + 1 >= assetPreviews.length ? currentIndex : currentIndex + 1; // End Scroll
            // const Next = (currentIndex+1>=assetPreviews.length)?0:currentIndex+1 // Loop scroll
            setSelectedAsset(assetPreviews[Next]);
        }
        if (touchStart - touchEnd < -SwipeTolerance) {
            const Prev = currentIndex - 1 < 0 ? currentIndex : currentIndex - 1; // End Scroll
            // const Prev = (currentIndex-1 < 0)?assetPreviews.length-1:currentIndex-1 // Loop scroll
            setSelectedAsset(assetPreviews[Prev]);
        }
    };

    return (
        <Box sx={assetGalleryStyles.container}>
            {modalReady && (
                <>
                    <Box sx={assetGalleryStyles.previewGallery}>
                        <Box sx={assetGalleryStyles.previewThumbnails}>
                            <Box sx={assetGalleryStyles.previewHeading}>Preview</Box>
                            <Box sx={assetGalleryStyles.imageContainer}>
                                {assetPreviews.map((assetPreview, mapIndex) => (
                                    <AssetPreviewThumbnail
                                        key={mapIndex}
                                        asset={assetPreview}
                                        selected={selectedAsset === assetPreview}
                                        index={mapIndex}
                                        clickHandler={handleNewAssetSelected}
                                        styles={assetGalleryStyles}
                                    />
                                ))}
                            </Box>
                        </Box>
                        <Box
                            sx={assetGalleryStyles.selected}
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={handleTouchEnd}>
                            <AssetPreview momentId={momentId} asset={selectedAsset} />
                        </Box>
                        <Box sx={assetGalleryStyles.buttonGroup}>
                            <ToggleButtonGroup
                                orientation='horizontal'
                                value={selectedAsset}
                                exclusive
                                onChange={handleToggleChange}>
                                {assetPreviews.map(assetPreview => (
                                    <ToggleButton
                                        key={assetPreview.momentID}
                                        value={assetPreview}
                                        sx={assetGalleryStyles.buttonColor}>
                                        <FiberManualRecord sx={assetGalleryStyles.buttonSize} />
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                    <Box sx={assetGalleryStyles.imageContent}>
                        <Box sx={assetGalleryStyles.heading}>
                            <Box sx={assetGalleryStyles.number}>#{index}</Box>
                            <Box sx={assetGalleryStyles.headingText}>{description}</Box>
                        </Box>
                        <Box sx={assetGalleryStyles.momentContent}>{MomentContents(assetPreviews, t)}</Box>
                        <Box sx={assetGalleryStyles.momentSizes}>
                            <AssetRatios />
                        </Box>
                        <Box sx={assetGalleryStyles.downloadButtonContainer}>
                            <Button
                                variant='contained'
                                onClick={downloadAllAssets}
                                sx={assetGalleryStyles.downloadButton}
                                endIcon={downloadEndIcon}>
                                Download All
                            </Button>
                            <FileDownloader url={downloadAssetsURL} downloadRef={fileDownloader} />
                        </Box>
                    </Box>
                </>
            )}
            <IconButton sx={assetGalleryStyles.closeButton} onClick={handleClose}>
                <Close />
            </IconButton>
        </Box>
    );
};

export default AssetGallery;
