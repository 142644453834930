import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Label from './Label';

const InputStyled = styled.input`
    width: 250px;
    height: 25px;
`;

const Input = ({
    name,
    type,
    placeholder,
    onChange,
    className,
    value,
    checked,
    error,
    children,
    label,
    onKeyPress
}) => (
    <>
        <Label title={label} htmlFor={name}>
            <InputStyled
                id={name}
                name={name}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                value={value || ''}
                checked={type === 'checkbox' && checked}
                className={className}
                style={error && { border: 'solid 1px red' }}
                onKeyPress={onKeyPress}
            />
            {children}
        </Label>
        {error && <p>{error}</p>}
    </>
);

Input.defaultProps = {
    type: 'text',
    placeholder: '',
    className: '',
    value: '',
    checked: false
};

Input.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

export default Input;
