import React, { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { MomentsHeader } from '../../Header/Header';
import Moments from '../../../Assets/Icons/moments.png';
import { ReactComponent as Download } from '../../../Assets/Icons/download.svg';
import { FileDownloader } from '../FileDownloader';
import { landscapeTabletBreakpoint } from '../../../constants';
import { ReactComponent as Checkmark } from '../../../Assets/Icons/checkmark.svg';
import { momentsStyles } from '../Styles/useStyles';
import { SERVER_ENDPOINT } from '../../../environment';

export const MomentLandingLeft = props => {
    const { scrollHeight, top, children } = props;
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation();

    const containerTop = top < 0 ? `${top}px` : '0px';

    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }
        function debouncedResize() {
            debounce(handleResize, 100, {
                leading: true,
                maxWait: 100,
                trailing: true
            });
        }
        window.addEventListener('resize', debouncedResize);
        handleResize();
        return () => window.removeEventListener('resize', debouncedResize);
    }, []);

    const containerRef = useRef(null);
    useEffect(() => {
        if (containerRef.current == null) {
            return;
        }

        let shouldBeVisible;
        if (windowSize.width < landscapeTabletBreakpoint) {
            shouldBeVisible = scrollHeight > containerRef.current.clientHeight;
        } else {
            shouldBeVisible = windowSize.width > landscapeTabletBreakpoint;
        }
        if (visible !== shouldBeVisible) {
            setVisible(shouldBeVisible);
        }
    }, [windowSize.width, scrollHeight, visible]);

    const [downloadAllAssetsURL, setDownloadAllAssetsURL] = useState('');
    const [isDownloaded, setIsDownloaded] = useState(false);
    const fileDownloader = useRef(null);
    const downloadAllAssets = () => {
        fetch(`${SERVER_ENDPOINT}/moments/all-moments-url`, {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => {
                setDownloadAllAssetsURL(data.momentURL);
            })
            .catch(error => console.log(error));
    };

    useEffect(() => {
        if (downloadAllAssetsURL) {
            fileDownloader.current.click();
            setIsDownloaded(true);
            setDownloadAllAssetsURL('');
        }
    }, [downloadAllAssetsURL]);

    const downloadEndIcon = isDownloaded ? (
        <Checkmark style={{ height: '14px', width: '17px' }} />
    ) : (
        <Download style={{ height: '14px', width: '17px' }} />
    );

    return (
        <>
            <Box sx={momentsStyles.container(containerTop)} ref={containerRef}>
                <Box sx={momentsStyles.title}>
                    <MomentsHeader />
                </Box>
                <Box sx={momentsStyles.bottomContainer}>
                    <Box component='img' sx={momentsStyles.sectionImg} src={Moments} alt={t('moments.title')} />
                    <Box sx={momentsStyles.headline}>{t('moments.title')}</Box>
                    <Box sx={momentsStyles.body}>{t('moments.description')}</Box>
                    <Box sx={momentsStyles.buttons}>
                        <Button
                            variant='contained'
                            onClick={downloadAllAssets}
                            sx={momentsStyles.downloadButton}
                            endIcon={downloadEndIcon}>
                            Download All
                        </Button>
                        <FileDownloader url={downloadAllAssetsURL} downloadRef={fileDownloader} />
                    </Box>
                    {children}
                </Box>
            </Box>
            {visible && (
                <Box sx={momentsStyles.stickyNavigationBar}>
                    <Box sx={momentsStyles.stickyHeading}>{t('moments.get_moments_label')}</Box>
                    <Button
                        variant='contained'
                        onClick={downloadAllAssets}
                        sx={momentsStyles.stickyButton}
                        endIcon={<Download style={{ height: '13.91px', width: '11.45px' }} />}>
                        {t('moments.download_all_btn_label')}
                    </Button>
                </Box>
            )}
        </>
    );
};
