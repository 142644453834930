import React, { useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Box, Button, Card } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import Bowser from 'bowser';
import { useTranslation } from 'react-i18next';
import { DISPLAY_CLIENT_NAME, LOGIN_BACKGROUND_COLOR_GRADIENT, LOGIN_BUTTON_COLOR } from '../../environment';
import { oktaConfig } from '../../oktaConfig';
import { NarrowFooter } from '../Header/Header';

function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
          }
        : null;
}

const loginButtonRgb = hexToRgb(LOGIN_BUTTON_COLOR) || { r: 0, g: 0, b: 0 };

const loginStyles = {
    container: {
        display: { xs: 'flex', md: 'unset' },
        flexDirection: { xs: 'column', md: 'unset' },
        width: '100%',
        height: '100%'
    },
    page: {
        background: LOGIN_BACKGROUND_COLOR_GRADIENT,
        height: 'inherit',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    card: {
        maxWidth: 344,
        margin: 'auto',
        bgcolor: 'white',
        p: '32px 32px 20px',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box'
    },
    messageCardWrapper: {
        p: { xs: '5px', md: 'unset' }
    },
    messageCard: {
        maxWidth: 880,
        m: 'auto',
        bgcolor: { xs: 'black', md: LOGIN_BUTTON_COLOR },
        color: 'white',
        p: '14px 24px',
        alignItems: 'left',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: { xs: 0, md: '4%' },
        left: { xs: 0, md: '50%' },
        transform: { md: 'translate(-50%, -4%)' },
        borderRadius: { xs: 0, md: 0.5 }
    },
    messageTitle: {
        fontSize: 12,
        lineHeight: '24px',
        fontWeight: 'bold'
    },
    messageText: {
        fontSize: 12,
        lineHeight: '24px'
    },
    loginButtonWrapper: {
        padding: '0px 20px 88px 20px'
    },
    loginButton: {
        textTransform: 'none',
        bgcolor: LOGIN_BUTTON_COLOR,
        '&:hover': {
            bgcolor: `rgba(${loginButtonRgb.r}, ${loginButtonRgb.g}, ${loginButtonRgb.b}, 0.9)`
        },
        borderRadius: 5,
        height: 72,
        width: '100%',
        fontSize: 14,
        lineHeight: '24px',
        justifyContent: 'space-between',
        p: '14px 24px',
        alignItems: 'center',
        fontFamily: 'ClientFontMedium',
        mb: 1
    },
    loginGuestButton: {
        textTransform: 'none',
        textDecoration: 'underline',
        fontSize: 15,
        letterSpacing: 0,
        color: LOGIN_BUTTON_COLOR,
        fontFamily: 'ClientFontRegular'
    },
    footer: {
        backgroundColor: 'black',
        display: { xs: 'flex', md: 'none', height: 'auto' },
        height: { xs: 'auto', md: 0 },
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'relative'
    }
};

export const LoginPage = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const history = useHistory();
    const { t } = useTranslation();

    const isUnsupportedSafari = useMemo(() => {
        const browser = Bowser.getParser(window.navigator.userAgent);
        return browser.satisfies({ safari: '<15' });
    }, []);

    if (authState && authState.isAuthenticated) {
        history.push('/');
        return null;
    }

    if (!oktaConfig.idp && !oktaConfig.multipleIDP) {
        oktaAuth.signInWithRedirect();
        return null;
    }

    const loginWithIDP = idp => async () => {
        try {
            await oktaAuth.token.getWithRedirect({
                idp
            });
        } catch (error) {
            console.log(error);
        }
    };

    const loginWithCustomEmail = async () => {
        try {
            await oktaAuth.signInWithRedirect();
        } catch (error) {
            console.log(error);
        }
    };

    const oktaIDPs = [];
    if (oktaConfig.multipleIDP) {
        Object.keys(oktaConfig.multipleIDP).forEach(key => {
            oktaIDPs.push({
                idp: oktaConfig.multipleIDP[key],
                text: `${key} login`
            });
        });
    } else if (oktaConfig.idp) {
        oktaIDPs.push({
            idp: oktaConfig.idp,
            text: `${DISPLAY_CLIENT_NAME} Login`
        });
    }
    return (
        <Box sx={loginStyles.container}>
            <Box sx={loginStyles.page}>
                <Box sx={loginStyles.loginButtonWrapper}>
                    <Card sx={loginStyles.card}>
                        {oktaIDPs.map(idpObj => (
                            <Button
                                sx={loginStyles.loginButton}
                                variant='contained'
                                color='primary'
                                key={idpObj.idp}
                                onClick={loginWithIDP(idpObj.idp)}
                                endIcon={<KeyboardArrowRight style={{ height: '32px', width: '32px' }} />}>
                                {idpObj.text}
                            </Button>
                        ))}
                        <Button
                            sx={loginStyles.loginGuestButton}
                            type='submit'
                            color='primary'
                            onClick={loginWithCustomEmail}>
                            {t('login.guest_email_login')}
                        </Button>
                    </Card>
                </Box>
                {isUnsupportedSafari && (
                    <Box sx={loginStyles.messageCardWrapper}>
                        <Card sx={loginStyles.messageCard}>
                            <Box sx={loginStyles.messageTitle}>{t('login.browser_performance_disclaimer_title')}</Box>
                            <Box sx={loginStyles.messageText}>{t('login.browser_performance_disclaimer_message')}</Box>
                        </Card>
                    </Box>
                )}
                <Box sx={loginStyles.messageCardWrapper}>
                    <Card sx={loginStyles.messageCard}>
                        <Box sx={loginStyles.messageTitle}>{t('login.cookie_consent_label')}</Box>
                        <Box sx={loginStyles.messageText}>{t('login.cookie_consent_message')}</Box>
                    </Card>
                </Box>
            </Box>
            <Box sx={loginStyles.footer}>
                <NarrowFooter />
            </Box>
        </Box>
    );
};
