import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ClientLogo } from '../../Assets/header_logo.svg';
import { mobileBreakpointPx, reducedSizeLandscapeBreakpointPx } from '../../constants';
import { ReactComponent as ClientLogoMoment } from '../../Assets/header_logo.svg';
import { ReactComponent as UnityLogo } from '../../Assets/Icons/UnityLogoWhite.svg';

const HeaderWrapper = () => (
    <Header>
        <UnityNYSELogos />
        <ClientLogo style={{ flexShrink: 0 }} />
        <LegalInfo />
    </Header>
);

export const Footer = () => (
    <Header>
        <UnityNYSELogos />
        <LegalInfo />
    </Header>
);

export const SmallerHeader = () => (
    <MobileHeader>
        <ClientLogo style={{ flexShrink: 0 }} />
    </MobileHeader>
);

export const NarrowFooter = () => {
    const { t } = useTranslation();

    return (
        <HeaderCenter>
            <UnityNYSELogos />
            <LegalCenter>
                <Link target='_blank' rel='noopener noreferrer' href='https://unity3d.com/legal'>
                    {t('header.legal_label')}
                </Link>
                <Link target='_blank' rel='noopener noreferrer' href='https://unity3d.com/legal/privacy-policy'>
                    {t('header.privacy_policy_label')}
                </Link>
            </LegalCenter>
            <CopyrightNarrow>{t('header.copyright_label')}</CopyrightNarrow>
        </HeaderCenter>
    );
};

export const UnityNYSELogos = () => (
    <HeaderLeft>
        <UnityLogo width='66' height='22' fill='#0A0B09' />
    </HeaderLeft>
);

export const LegalInfo = () => {
    const { t } = useTranslation();

    return (
        <HeaderRight>
            <LegalWrapper>
                <Legal>
                    <Link target='_blank' rel='noopener noreferrer' href='https://unity3d.com/legal'>
                        {t('header.legal_label')}
                    </Link>
                    <Link target='_blank' rel='noopener noreferrer' href='https://unity3d.com/legal/privacy-policy'>
                        {t('header.privacy_policy_label')}
                    </Link>
                </Legal>
                <Copyright>{t('header.copyright_label')}</Copyright>
            </LegalWrapper>
        </HeaderRight>
    );
};

// LP-4654: reduced logo size on mobile.
const ClientLogoStyled = styled(ClientLogoMoment)`
    margin: 0px;
    padding: 0px;
    border: 0px;
    height: 96px;
    @media (max-width: ${mobileBreakpointPx}) and (orientation: portrait) {
        max-height: 48px;
    }
`;

export const MomentsHeader = () => (
    <HeaderMiddle>
        <ClientLogoStyled height='32px' />
    </HeaderMiddle>
);

const Copyright = styled.span`
    color: #ffffff;
    font-family: InterRegular;
    font-size: 12px;
    letter-spacing: 0;
`;
const CopyrightNarrow = styled.span`
    color: #ffffff;
    font-family: InterRegular;
    font-size: 12px;
    letter-spacing: 0;
    margin-top: 8px;
    text-align: center;
`;

const Link = styled.a`
    text-decoration: none;
    font-family: InterRegular;
    color: white;
    font-size: 12px;
    letter-spacing: 0;
    text-decoration: underline;
`;

const Header = styled.div`
    width: 100%;
    padding: 0px 5%;
    height: 72px;
    background: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    max-width: calc(100vh * 16 / 9);
    margin: 0 auto;
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome and Opera */
`;

const HeaderRight = styled.div`
    display: flex;
    flex: 0 0 160px;
    justify-content: center;
`;

const LegalWrapper = styled.div`
    flex-shrink: 0;
    opacity: 0.6;
`;
const HeaderLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    flex: 0 0 160px;
`;

const HeaderMiddle = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    min-width: 420px;
    margin: 0 auto;
    margin-top: 4.5vh;
`;

const HeaderCenter = styled.div`
    display: inline;
    width: 100%;
    padding: 20px 5%;
    background: black;
    align-items: center;
    color: white;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    vertical-align: baseline;
    display: grid;
    grid-auto-flow: row;
`;

const MobileHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    @media (max-width: ${reducedSizeLandscapeBreakpointPx}) and (orientation: landscape) {
        display: none;
    }
`;

const Legal = styled.div`
    display: flex;
    justify-content: space-between;
`;

const LegalCenter = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    justify-content: center;
    margin-top: 16px;
`;

export default HeaderWrapper;
