export const OKTA_ISSUER = process.env.REACT_APP_OKTA_ISSUER;
export const OKTA_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const OKTA_IDP = process.env.REACT_APP_OKTA_IDP;
export const OKTA_MULTIPLE_IDP = process.env.REACT_APP_OKTA_MULTIPLE_IDP;
export const SERVER_ENDPOINT = process.env.REACT_APP_ENDPOINT;
export const CLIENT_NAME = process.env.REACT_APP_CLIENT_NAME;
export const DISPLAY_CLIENT_NAME = process.env.REACT_APP_DISPLAY_CLIENT_NAME || process.env.REACT_APP_CLIENT_NAME;
export const BRANCH_NAME = process.env.REACT_APP_BRANCH_NAME;
export const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR || '#000000';
export const SECONDARY_COLOR = process.env.REACT_APP_SECONDARY_COLOR || '#2196F3';
export const TERTIARY_COLOR = process.env.REACT_APP_TERTIARY_COLOR || '#FFFFFF';
export const MOMENTS_COLOR_GRADIENT = process.env.REACT_APP_MOMENTS_COLOR_GRADIENT || PRIMARY_COLOR;
export const MOMENTS_COLOR_BACKGROUND = process.env.REACT_APP_MOMENTS_COLOR_BACKGROUND || '#0D0D0D';
export const LOGIN_BUTTON_COLOR = process.env.REACT_APP_LOGIN_BUTTON_COLOR || PRIMARY_COLOR;
export const LOGIN_BACKGROUND_COLOR_GRADIENT =
    process.env.REACT_APP_LOGIN_BACKGROUND_COLOR_GRADIENT ||
    `linear-gradient(180deg, #000000 0%, ${PRIMARY_COLOR} 100%)`;
export const VIMEO_FALLBACK_LINK = process.env.REACT_APP_VIMEO_FALLBACK_LINK;
export const VIMEO_FALLBACK_LINK_PASSWORD = process.env.REACT_APP_VIMEO_FALLBACK_LINK_PASSWORD;
export const FIREBASE_CONFIG = process.env.REACT_APP_FIREBASE_CONFIG;
export const WEBGL_PUBLIC_URL = process.env.REACT_APP_WEBGL_PUBLIC_URL;
export const { LOCALIZATION_DATA } = process.env;
export const PHOTOBOOTH_HIDDEN = (process.env.REACT_APP_HIDE_PHOTOBOOTH || 'false').toLowerCase() === 'true';
export const MOMENTS_HIDDEN = (process.env.REACT_APP_HIDE_MOMENTS || 'false').toLowerCase() === 'true';
export const CELEBRATION_ENABLED = !MOMENTS_HIDDEN || !PHOTOBOOTH_HIDDEN;
export const USE_DEVELOPMENT = (process.env.REACT_APP_USE_DEVELOPMENT || 'false').toLowerCase() === 'true';
