import { useOktaAuth } from '@okta/okta-react';
import { AssignmentInd } from '@mui/icons-material';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PRIMARY_COLOR } from '../../environment';

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #000000 0%, ${PRIMARY_COLOR} 100%);
    height: 100%;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
`;

const RetryButton = styled.button`
    height: 48px;
    width: 90px;
    border-radius: 5px;
    background-color: #ffffff;
    color: ${PRIMARY_COLOR};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.47px;
    border: none;

    &:hover {
        cursor: pointer;
        background-color: #d2d6fd;
    }

    &:active {
        color: #ffffff;
        background-color: ${PRIMARY_COLOR};
    }
`;

const ErrorMessage = styled.span`
    font-size: 24px;
    font-weight: 600;
    color: white;
    margin: 32px 0;
    text-align: center;
`;

const ErrorComponent = ({ error }) => {
    const { oktaAuth } = useOktaAuth();
    const { t } = useTranslation();

    const errorMessage = !error ? t('login.error_message') : error.errorSummary || error.message;

    const handleLogout = () => {
        oktaAuth.signOut('/');
    };

    return (
        <ErrorContainer>
            <AssignmentInd htmlColor='white' style={{ height: '72px', width: '72px' }} />
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <RetryButton type='button' onClick={handleLogout}>
                {t('login.retry_button')}
            </RetryButton>
        </ErrorContainer>
    );
};

const LoginCallbackFixed = () => {
    const { oktaAuth } = useOktaAuth();
    const [error, setError] = useState(null);

    useEffect(() => {
        const handleRedirect = async () => {
            try {
                await oktaAuth.handleLoginRedirect();
            } catch (loginRedirectError) {
                setError(loginRedirectError);
            }
        };
        handleRedirect();
    }, [oktaAuth]);

    if (error) {
        return <ErrorComponent error={error} />;
    }

    return null;
};

export const LoginCallbackWithError = () => <LoginCallbackFixed />;
