import { Box } from '@mui/material';

const Label = ({ title, children, htmlFor }) => (
    <Box
        component='label'
        htmlFor={htmlFor}
        sx={{
            display: 'block',
            '& > *': {
                display: 'block',
                mb: '1em'
            }
        }}>
        {title}
        {children}
    </Box>
);

export default Label;
